import { translate } from '../global/translate';
import {
  BudgetDocument,
  BudgetFrequency,
  GroupDocument,
  UntrackedBudgetId,
} from '@og-shared/types';
import {
  IGNORE_BUDGET_ID,
  UNCATEGORIZED_BUDGET_ID,
  exhaustiveCheck,
} from '@og-shared/utils';

export const budgetTypes: BudgetTypeById = {
  INCOME: {
    color: 7,
    icon: 'cash',
    translateKey: 'OG_INCOME',
    value: 'INCOME',
  },
  SAVINGS: {
    color: 9,
    icon: 'heart',
    translateKey: 'SAVING_S',
    value: 'SAVINGS',
  },
  BILL: {
    color: 5,
    icon: 'calendar',
    translateKey: 'BILL_S',
    value: 'BILL',
  },
  SPENDING: {
    color: 12,
    icon: 'mail',
    translateKey: 'SPENDING',
    value: 'SPENDING',
  },
  // OTHER: {
  //   color: 17,
  //   icon: 'help',
  //   translateKey: 'UNCATEGORIZED',
  //   value: 'OTHER',
  // },
};

export const getDefaultBudgets = (): {
  [key in UntrackedBudgetId]: BudgetDocument;
} => {
  return {
    [IGNORE_BUDGET_ID]: {
      deleted: false,
      budget_id: IGNORE_BUDGET_ID,
      name: translate('IGNORE'),
      color: 18,
      icon: 'close',
      available: 0,
      budget: 0,
      fill_amount: 0,
      per_year: 0,
      type: 'INCOME',
      og_category: 'OG_UNKNOWN',
    },
    [UNCATEGORIZED_BUDGET_ID]: {
      deleted: false,
      budget_id: UNCATEGORIZED_BUDGET_ID,
      name: translate('UNCATEGORIZED'),
      color: 17,
      icon: 'help',
      available: 0,
      budget: 0,
      fill_amount: 0,
      per_year: 0,
      og_category: 'OG_UNKNOWN',
      type: null as any,
    },
  };
};

export function getBudgetFrequencySimpleName(frequency: BudgetFrequency | 365) {
  switch (frequency) {
    case 0: {
      return ` ${translate('NO_REPEAT')}`;
    }
    case 1: {
      return ` / ${translate('YEAR_S', 1)}`;
    }
    case 2: {
      return ` / ${translate('SMART_COUNT_MONTH', 6)}`;
    }
    case 4: {
      return ` / ${translate('SMART_COUNT_MONTH', 3)}`;
    }
    case 6: {
      return ` / ${translate('SMART_COUNT_MONTH', 2)}`;
    }
    case 12: {
      return ` / ${translate('MONTH_S', 1)}`;
    }
    case 24: {
      return translate('TWICE_MONTH_SIMPLE');
    }
    case 26: {
      return ` / ${translate('SMART_COUNT_WEEK', 2)}`;
    }
    case 52: {
      return ` / ${translate('WEEK_S', 1)}`;
    }
    case 365: {
      return `/ ${translate('DAY_S', 1)}`;
    }
    default: {
      exhaustiveCheck(frequency);
    }
  }
}

export function getBudgetFrequencyName(frequency: BudgetFrequency | 365) {
  switch (frequency) {
    case 0: {
      return translate('NO_REPEAT');
    }
    case 1: {
      return translate('EVERY_NUM_FREQUENCY', {
        frequency: translate('YEAR_S', 1),
      });
    }
    case 2: {
      return translate('EVERY_NUM_FREQUENCY', {
        frequency: translate('SMART_COUNT_MONTH', 6),
      });
    }
    case 4: {
      return translate('EVERY_NUM_FREQUENCY', {
        frequency: translate('SMART_COUNT_MONTH', 3),
      });
    }
    case 6: {
      return translate('EVERY_NUM_FREQUENCY', {
        frequency: translate('SMART_COUNT_MONTH', 2),
      });
    }
    case 12: {
      return translate('EVERY_NUM_FREQUENCY', {
        frequency: translate('MONTH_S', 1),
      });
    }
    case 24: {
      return translate('TWICE_EVERY_MONTH');
    }
    case 26: {
      return translate('EVERY_NUM_FREQUENCY', {
        frequency: translate('SMART_COUNT_WEEK', 2),
      });
    }
    case 52: {
      return translate('EVERY_NUM_FREQUENCY', {
        frequency: translate('WEEK_S', 1),
      });
    }
    case 365: {
      return;
    }
    default: {
      exhaustiveCheck(frequency);
    }
  }
}

export const groupBudgetTypes: GroupDocument['budget_type'][] = [
  'envelope',
  'report',
];

export const SPENDING_BY_TYPE_ID = 'spending-by-type' as const;
export const INCOME_VS_EXPENSES_ID = 'income-vs-expenses-group' as const;

/** @deprecated not actually - just not supported yet */
export const SPENDING_BY_ACCOUNT_ID = 'spending-by-accounts-group' as const;
